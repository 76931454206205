<template>
  <b-form-group>
    <template v-if="label" v-slot:label>
      <label>
        {{ label }}

        <span v-if="required" class="text-danger"> *</span>
      </label>
    </template>

    <b-input-group>
      <b-input-group-prepend
        v-if="icon"
        is-text
        :class="{ 'icon-button': copyable }"
        :title="copyable && 'Copiar texto para área de transferência'"
        @click="useClipboard"
      >
        <b-icon :icon="icon" :rotate="rotateIcon" />
      </b-input-group-prepend>

      <b-form-input
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :type="type"
        :spellcheck="spellcheck"
        :state="validationState"
        @change="$emit('change', $event)"
        @input="$emit('input', $event)"
        @keydown.enter="$emit('enter', value)"
        @keypress="$emit('keypress', $event)"
      />

      <b-input-group-append v-if="appendIcon">
        <b-button
          :variant="appendVariant"
          @click="$emit('apped-click', $event)"
        >
          <b-icon :icon="appendIcon" />
        </b-button>
      </b-input-group-append>

      <b-form-invalid-feedback>
        {{ validationMessage }}
      </b-form-invalid-feedback>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "FormInput",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
    },
    spellcheck: {
      type: Boolean,
      required: false,
    },
    copyable: {
      type: Boolean,
      required: false,
    },
    rotateIcon: {
      type: String,
      required: false,
    },
    appendIcon: {
      type: String,
      required: false,
    },
    appendVariant: {
      type: String,
      required: false,
      default: "primary",
    },
  },
  data() {
    return {
      toggleEye: true,
    };
  },
  computed: {
    validationState() {
      return this.errors[0] ? false : null;
    },
    validationMessage() {
      return this.errors[0];
    },
  },
  methods: {
    useClipboard() {
      if (this.copyable) {
        navigator.clipboard.writeText(this.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-button {
  cursor: pointer;
}
</style>
