<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Departamento</h4>
        </div>
        <div class="d-flex justify-content-end aling-items-center">
          <search-input
            v-model="searchDepartment"
            :is-loading-search="isLoadingSearch"
            @enter="search"
          />

          <b-button
            class="ml-2 mb-2"
            variant="primary"
            @click="openCreateDepartmentModal"
            >CRIAR DEPARTAMENTO</b-button
          >
        </div>

        <b-card-text class="d-flex flex-column">
          <b-table
            :items="allDepartment"
            :fields="departmentFields"
            :busy="isLoading || isLoadingSearch"
            bordered
            striped
            hover
          >
            <template #cell(actions)="row">
              <b-button
                size="sm"
                variant="primary"
                title="Adicione as informações do departamento"
                class="mr-1"
                @click="showEditDepartmentModal(row.item)"
              >
                <b-icon icon="pencil"></b-icon>
              </b-button>

              <b-button
                size="sm"
                title="Desativar departamento"
                @click="showDeactiveDepartmentModal(row.item)"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
          <b-button v-if="showButton" @click="getMore"
            >CARREGAR MAIS
            <b-icon
              v-if="isLoading"
              icon="arrow-clockwise"
              animation="spin"
            ></b-icon
          ></b-button>
        </b-card-text>

        <b-modal
          v-model="createDepartmentModal"
          title="Adicione as informações do departamento"
          hide-footer
        >
          <b-form @submit.prevent="createDepartment">
            <form-input
              v-model="departmentDescription"
              icon="pencil"
              label="Descrição"
              :errors="errors['description']"
              required
            />

            <div class="d-flex justify-content-end">
              <b-button variant="primary" type="submit"> SALVAR </b-button>
            </div>
          </b-form>
        </b-modal>

        <b-modal
          v-model="editDepartmentModal"
          title="Adicione as informações do departamento"
          hide-footer
        >
          <b-form @submit.prevent="editDescription">
            <form-input
              v-model="departmentDescription"
              icon="pencil"
              label="Descrição"
              :errors="errors['description']"
              required
            >
            </form-input>

            <div class="d-flex justify-content-end">
              <b-button variant="primary" type="submit"> SALVAR </b-button>
            </div>
          </b-form>
        </b-modal>

        <b-modal
          v-model="deactiveDepartmentModal"
          title="Desativar"
          hide-footer
        >
          <p>
            Tem certeza que deseja desativar o departamento "{{
              departmentDescription
            }}"
          </p>
          <div class="d-flex justify-content-end" @click="deactiveDepartment">
            <b-button>DESATIVAR</b-button>
          </div>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";
import FormInput from "../../components/form/FormInput.vue";
import SearchInput from "../../components/shared/SearchInput.vue";

export default {
  components: { FormInput, SearchInput },
  data() {
    return {
      page: 2,
      isLoading: false,
      showButton: true,
      isLoadingSearch: false,
      searchDepartment: "",
      departmentId: null,
      departmentDescription: "",
      editDepartmentModal: false,
      deactiveDepartmentModal: false,
      createDepartmentModal: false,
      allDepartment: [],
      errors: {},
      departmentFields: [
        {
          key: "description",
          label: "Descrição",
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: { width: "100px !important" },
        },
      ],
    };
  },

  methods: {
    ...mapMutations(["setToastedError", "setToastedSuccess", "setOverlay"]),

    showEditDepartmentModal(item) {
      this.errors = {};
      this.departmentDescription = item.description;
      this.departmentId = item.id;
      this.editDepartmentModal = true;
    },

    showDeactiveDepartmentModal(item) {
      this.errors = {};
      this.departmentId = item.id;
      this.departmentDescription = item.description;
      this.deactiveDepartmentModal = true;
    },

    openCreateDepartmentModal() {
      this.errors = {};
      this.departmentDescription = "";
      this.createDepartmentModal = true;
    },

    async getMore() {
      if (this.searchDepartment) {
        this.isLoadingSearch = true;

        const { data } = await http(`/department`, {
          params: {
            search: this.searchDepartment,
            page: this.page,
          },
        });

        this.allDepartment = [...this.allDepartment, ...data];
        this.isLoadingSearch = false;
        this.showButton = data.length === 20;
      } else {
        this.isLoading = true;
        const { data } = await http(`department?page=${this.page}`);
        this.allDepartment.push(...data);
        this.showButton = data.length === 20;
        this.isLoading = false;
      }
      this.page += 1;
    },

    async search() {
      this.page = 2;
      if (this.searchDepartment) {
        this.isLoadingSearch = true;

        const { data } = await http(`/department`, {
          params: {
            search: this.searchDepartment,
          },
        });

        this.allDepartment = data;
        this.isLoadingSearch = false;
        this.showButton = data.length === 20;
      } else {
        this.isLoading = true;
        const { data } = await http(`department`);
        this.allDepartment = data;
        this.isLoading = false;
        this.showButton = data.length === 20;
      }
    },

    async createDepartment() {
      this.errors = {};
      try {
        this.createDepartmentModal = false;
        this.setOverlay(true);
        const { data } = await http.post("/department", {
          description: this.departmentDescription,
        });
        this.allDepartment.push(data);
        this.setOverlay(false);
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          console.log(err);
        }
      }
    },

    async editDescription() {
      this.errors = {};
      try {
        const { data } = await http.put(`/department/${this.departmentId}`, {
          description: this.departmentDescription,
        });

        const index = this.allDepartment.findIndex(
          (department) => department.id === data.id
        );
        this.allDepartment.splice(index, 1, data);
        this.editDepartmentModal = false;
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          console.log(err);
        }
      }
    },

    async deactiveDepartment() {
      this.deactiveDepartmentModal = false;
      this.setOverlay(true);
      await http.put(`/department/deactive/${this.departmentId}`);

      const department = this.allDepartment.filter(
        (department) => department.id !== this.departmentId
      );

      this.allDepartment = department;
      this.setOverlay(false);
    },
  },

  async created() {
    this.setOverlay(true);
    const { data } = await http("department?page=1");
    this.allDepartment.push(...data);
    this.showButton = data.length === 20;
    this.setOverlay(false);
  },
};
</script>
