<template>
  <b-form-group :label="label" :class="{ 'apeu-search-input': !full }">
    <b-input-group>
      <b-input-group-prepend is-text>
        <b-icon-arrow-clockwise v-if="isLoadingSearch" animation="spin" />
        <b-icon-search v-else />
      </b-input-group-prepend>

      <b-form-input
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :autofocus="initialFocus"
        ref="searhInputKeyBoard"
        @input="$emit('input', $event)"
        @keydown.enter="$emit('enter', value)"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "SearchInput",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    isLoadingSearch: {
      type: Boolean,
      required: false,
    },
    full: {
      type: Boolean,
      required: false,
    },
    initialFocus: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    escapeListener(event) {
      if (event.key === "s" && event.ctrlKey) {
        event.preventDefault();
        this.$refs.searhInputKeyBoard.focus();
      }
    },
  },
  created() {
    window.addEventListener("keydown", this.escapeListener);
  },
  destroyed() {
    window.removeEventListener("keydown", this.escapeListener);
  },
};
</script>

<style lang="scss" scoped>
.apeu-search-input {
  width: 350px;
}
</style>
